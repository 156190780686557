import { AreaPageListing } from '@zoocasa/go-search';
import { AreaPageListingCardData } from 'components/dynamic-page/new-area-listings-page/area_page_listing_card_data';
import { AreaListingsRouteMatchObject, generateRouteMatchObjectFromPath, ROOT_ROUTE } from 'components/dynamic-page/route-matchers';
import HomePage, { HomePageProps } from 'components/home-page';
import { fetchHomePageListings, fetchHomePageListingsWithBoundary, ListingsQueryType } from 'components/home-page/fetcher';
import { ListingCardData } from 'components/listing-card';
import configJSON from 'config.json';
import { getLastSearchLocationTag, HIDE_NOTIFICATION_BANNER_COOKIE_NAME } from 'constants/cookies';
import { CACHE_CONTROL_HEADER_NAME } from 'constants/headers';
import { defaultHomeType } from 'constants/listing-components';
import { defaultCACityPayload, defaultUSCityPayload } from 'constants/locations';
import { getSiteLocationFromRequest } from 'utils/site-location';
import { AREA_PAGE_CONSTANTS } from 'constants/pagination';
import { LastSearchLocation } from 'contexts';
import { AVAILABLE_STATUS, Filter, ListingParams } from 'contexts/preferences/listing-params/types';
import { createNamedContentsApi } from 'data/named-content';
import { checkLocationHasAreaPageWithId } from 'data/search-predictions';
import { useState, useMemo } from 'react';
import { PartialDeep } from 'type-fest';
import { CountryCodeList, type CountryCode } from 'types/countries';
import { ThemeNames } from 'types/themes';
import { getNSEWWithBoundaryString } from 'utils/boundary';
import { getObjectFromRequestCookie } from 'utils/cookies';
import isUserAgentCrawler from 'utils/crawler-agent';
import extractPageFromUrl, { DEFAULT_PAGE_NUMBER } from 'utils/extract-page-from-url';
import { getFeaturesOverrideFromRequest } from 'utils/features';
import { getSlugFallbackFromURLSearchParams, getSortFromURLSearchParams } from 'utils/listing-query-helper';
import { countryCodeFromProvinceOrState } from 'utils/province_or_state';
import { sanitizeProvinceOrStateCode } from 'utils/sanitize';
import { getThemeOverrideFromRequest, themeOrDefault } from 'utils/themes';
import { getUserLocationFromServerSideRequest } from 'utils/user-location';
import { isLastSearchLocationValid } from 'utils/validate-location';
import { isThemeExpInService, isThemeExpUS, isThemeExpCA } from 'utils/tenant/which-tenant';
import { getTenantById } from 'tenants/manager';
import { HomeConfig } from 'tenants/tenants';

import type { GetServerSidePropsContext, GetServerSidePropsResult } from 'next';
import { getSupportedUserLocation } from 'utils/user-location/user-location';

export interface INamedContents {
  featuredHomesMlsNums: string[];
}

type RootPageProps = Omit<HomePageProps, 'availableHomeListings'> & {
  availableHomeListingsData: AreaPageListing[];
  tenantHomeConfig?: HomeConfig;
};

export async function getServerSideProps(context: GetServerSidePropsContext): Promise<GetServerSidePropsResult<RootPageProps>> {
  context.res.setHeader(CACHE_CONTROL_HEADER_NAME, 'public, s-maxage=43200, stale-while-revalidate=60');

  const { req: request } = context;
  const themeName = themeOrDefault(getThemeOverrideFromRequest(request));
  const tenantConfig = getTenantById(themeName)?.config;
  const isCrawler = isUserAgentCrawler(request.headers);
  const { featuredHomesMlsNums } = await getHomeConfig();
  const notificationBannerCookie = getObjectFromRequestCookie<boolean>(HIDE_NOTIFICATION_BANNER_COOKIE_NAME, request);
  const isNotificationBannerHidden = !configJSON.features.useNotificationBanner || !!notificationBannerCookie;
  // Get homepage listings server-side
  const userLocation = await getUserLocationFromServerSideRequest(request);
  const siteLocation = getSiteLocationFromRequest(request, userLocation?.countryCode);
  let parsedUserLocation = userLocation;
  // If user is not in country of SiteLocation, set defaults
  parsedUserLocation = getSupportedUserLocation(themeName, userLocation);

  const lastSearchLocation = getObjectFromRequestCookie<LastSearchLocation>(getLastSearchLocationTag(themeName), request);
  const featureOverrides = getFeaturesOverrideFromRequest(request);
  const features = { ...configJSON.features, ...featureOverrides };

  const extractLocation = (locationName?: string) => {
    if (locationName && typeof locationName === 'string') {
      const parts = locationName?.split(',');
      return parts?.slice(-2)?.join(',')?.trim();
    } else {
      return userLocation.countryCode !== CountryCodeList.CANADA ? defaultCACityPayload.name : defaultUSCityPayload.name;
    }
  };

  const locationSource = lastSearchLocation?.description || parsedUserLocation?.name || defaultCACityPayload.name;
  const locationName = extractLocation(locationSource);

  let availableHomeListingsData: AreaPageListing[];
  let availableCount: number;
  let isExpandedArea: boolean;

  const { routeMatchObject } = extractUrlData(context);
  const provinceOrState: string = sanitizeProvinceOrStateCode(routeMatchObject.provinceCode);
  const country = routeMatchObject.country?.toUpperCase() as CountryCode || countryCodeFromProvinceOrState(provinceOrState);
  const internalLinksJSON = themeName === ThemeNames.ZOOCASA ?
    await import('components/home-page/internal-links/data.json') :
    await import('components/home-page/internal-links-exp/data.json');

  const generateInternalLinks = () => {
    switch (themeName) {
    case ThemeNames.EXP_REALTY_CA:
      return internalLinksJSON['exp-ca-links'] || [];
    case ThemeNames.EXP_REALTY_US:
    case ThemeNames.EXP_IN_SERVICE:
      return internalLinksJSON['exp-us-links'] || [];
    case ThemeNames.ZOOCASA:
      if (siteLocation === CountryCodeList.UNITED_STATES) {
        return internalLinksJSON['usa-links'] || [];
      } else if (siteLocation === CountryCodeList.CANADA) {
        return internalLinksJSON['canadian-links'] || [];
      }
      break;
    default:
      return internalLinksJSON['canadian-links'] || [];
    }
  };

  const internalLinksData = generateInternalLinks();

  if (isLastSearchLocationValid(lastSearchLocation) && !checkLocationHasAreaPageWithId(lastSearchLocation.id)) {
    const { north, south, east, west } = getNSEWWithBoundaryString(lastSearchLocation.boundary);
    const availableRes = await fetchHomePageListingsWithBoundary({ north, south, east, west }, themeName, false, features.useLegacySearchFilter || false);
    const { totalCount, listings } = availableRes;
    if (totalCount > 0) {
      availableCount = totalCount;
      availableHomeListingsData = listings.map<AreaPageListing>((listing: AreaPageListing) => AreaPageListing.toJSON(listing) as AreaPageListing);
      isExpandedArea = true;
      return {
        props: {
          isCrawler,
          featuredHomesMlsNums,
          isNotificationBannerHidden,
          availableHomeListingsData,
          availableHomeListingsMeta: availableCount,
          availableHomeListingsLocation: locationName,
          isExpandedArea,
          internalLinksData,
          tenantName: themeName,
          tenantHomeConfig: tenantConfig?.home || null,
        }};
    }
  }
  // Fallback to slug based search if no listings obtained from boundary search
  const { params, slugFallback } = extractUrlData(context);
  const { filter } = params;
  const parsedSlug = lastSearchLocation?.slug
    ? lastSearchLocation.slug
    : lastSearchLocation?.id?.replace(/^location-/, '');
  const parsedSlugWithFallback = parsedSlug || parsedUserLocation?.slug || defaultCACityPayload.slug;
  const areaPageSlug = grabSlug(parsedSlugWithFallback, slugFallback) || '';
  let queryCountry: CountryCode;
  if (parsedSlugWithFallback.length === 0) {
    queryCountry = CountryCodeList.CANADA;
  } else {
    queryCountry = !isCrawler ? countryCodeFromProvinceOrState(parsedSlugWithFallback?.slice(-2)) : country;
  }
  const slug = parsedSlugWithFallback && !isCrawler ? parsedSlugWithFallback : areaPageSlug;

  const availableListingsQuery: ListingsQueryType = {
    country: queryCountry && !isCrawler ? queryCountry : country,
    slug: slug,
    filter: {
      ...filter,
      homeType: defaultHomeType,
      status: AVAILABLE_STATUS,
      hasImage: true,
      tenant: themeName,
    },
    pageSize: 15,
    useLegacySearchFilter: features.useLegacySearchFilter || false,
  };
  const availableRes = await fetchHomePageListings(availableListingsQuery);
  const { totalCount, listings, isExpandedArea: isExpanded } = availableRes;

  availableCount = totalCount;
  availableHomeListingsData = listings.map<AreaPageListing>((listing: AreaPageListing) => AreaPageListing.toJSON(listing) as AreaPageListing);
  isExpandedArea = isExpanded;


  return {
    props: {
      isCrawler,
      featuredHomesMlsNums,
      isNotificationBannerHidden,
      availableHomeListingsData,
      availableHomeListingsMeta: availableCount,
      availableHomeListingsLocation: locationName,
      isExpandedArea,
      internalLinksData,
      tenantName: themeName,
      tenantHomeConfig: tenantConfig?.home || null,
    }};
}


export default function RootPage({
  isCrawler,
  featuredHomesMlsNums,
  isNotificationBannerHidden,
  availableHomeListingsData,
  availableHomeListingsMeta,
  availableHomeListingsLocation,
  isExpandedArea = false,
  internalLinksData,
  tenantName,
  tenantHomeConfig,
}: RootPageProps) {

  const availableHomeListings = useMemo(() =>
    availableHomeListingsData
      .map((listing: AreaPageListing) => AreaPageListing.fromJSON(listing))
      .map((listing: AreaPageListing) => areaPageListingToListingCardData(listing)),
  [availableHomeListingsData]
  );

  return <HomePage
    isCrawler={isCrawler}
    featuredHomesMlsNums={featuredHomesMlsNums}
    isNotificationBannerHidden={isNotificationBannerHidden}
    availableHomeListings={availableHomeListings}
    availableHomeListingsMeta={availableHomeListingsMeta}
    availableHomeListingsLocation={availableHomeListingsLocation}
    isExpandedArea={isExpandedArea}
    internalLinksData={internalLinksData}
    routeName={ROOT_ROUTE}
    tenantName={tenantName}
    tenantHomeConfig={tenantHomeConfig}
  />;
}

async function getHomeConfig() {
  const namedContentsApi = createNamedContentsApi();
  const config = await namedContentsApi.getHomeConfigNamedContent();
  return (config && config?.content) || { featuredHomesMlsNums: []};
}

//FIXME: remove this
export function extractUrlData(context: GetServerSidePropsContext, showMapCardExperimentView?: boolean) {
  const { query, resolvedUrl } = context;
  const routeMatchObject: AreaListingsRouteMatchObject = generateRouteMatchObjectFromPath(resolvedUrl, false);
  const filterFromRouteMatchObject: PartialDeep<Filter> = routeMatchObject.filter;
  const url = new URL(`https://example.com${resolvedUrl}`);
  const urlSearchParams: URLSearchParams = new URLSearchParams(url.search);
  const params: Required<ListingParams> = {
    sort: getSortFromURLSearchParams(urlSearchParams),
    page: {
      number: Math.max(extractPageFromUrl(query), DEFAULT_PAGE_NUMBER),
      size: showMapCardExperimentView ? AREA_PAGE_CONSTANTS.DEFAULT_PAGE_SIZE - 1 : AREA_PAGE_CONSTANTS.DEFAULT_PAGE_SIZE,
    },
    filter: { ... filterFromRouteMatchObject },
  } as Required<ListingParams>;
  const slugFallback = getSlugFallbackFromURLSearchParams(urlSearchParams);
  return { params, path: resolvedUrl, routeMatchObject, slugFallback };
}

//FIXME: remove this
export function grabSlug(slug: string, slugFallback: string | null) {
  let areaPageSlug = (slugFallback && slugFallback?.length > 0) ? slugFallback : slug;
  if (areaPageSlug.includes('/')) {
    areaPageSlug = areaPageSlug.replace(/^(.*?)\/(.*)$/, '$2-$1');
  }
  return areaPageSlug;
}

export const areaPageListingToListingCardData: (listing: AreaPageListing) => ListingCardData = (listing: AreaPageListing) => (new AreaPageListingCardData(listing));